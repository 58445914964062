
import { Component, Vue } from "vue-property-decorator";
import EnterprisePartner from "@/components/Academy/Utility/EnterprisePartner.vue";
import TigerSchoolFootBanner from "@/components/Academy/Utility/TigerSchoolFootBanner.vue";
import TigerSchoolBanner from "@/components/Academy/Utility/TigerSchoolBanner.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

@Component({
  components: {
    EnterprisePartner,
    TigerSchoolFootBanner,
    TigerSchoolBanner,
    Swiper,
    SwiperSlide,
  },
})
export default class Course extends Vue {
  isMobile = window.innerWidth <= 768;
  //data
  bannerOption = {
    back: "返回師虎學院",
    title: "師虎學院培訓課程",
    btnText: "立即報名",
    linkUrl: "/Academy/CourseSignUp",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/001.jpg")})`,
    },
  };
  footbannerOption = {
    btnText: "立即報名",
    linkUrl: "/Academy/CourseSignUp",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/course.jpg")})`,
    },
  };
  coursesList = [
    {
      title: "嵌入式設備安裝課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/015.jpg",
      desc: ["2小時", "家電設備適應各種現場安裝，設備測試、實際操作"],
      display: true,
    },
    {
      title: "監視系統安裝課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/014.png",
      desc: ["2小時", "監視系統安裝及連線設定之實際操作"],
      display: true,
    },
    {
      title: "木工課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/004.jpg",
      desc: ["4小時", "學習基礎木工"],
      display: true,
    },
    {
      title: "清潔課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/005.jpg",
      desc: ["8小時", "學習基礎清潔方法", "工具的選擇與使用"],
      display: false,
    },
    {
      title: "疏通工程課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/006.jpg",
      desc: ["8小時", "學習疏通過程與原理 ", "了解堵塞的原因與處理"],
      display: false,
    },
    {
      title: "電力課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/007.jpg",
      desc: [
        "8小時",
        "學習基礎電力的種類",
        "學習插座、開關、燈具",
        "學習配電方式的種類",
      ],
      display: false,
    },
    {
      title: "安裝工程課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/008.jpg",
      desc: ["4小時", "學習基礎安裝的方法", "學習各工具的使用方式"],
      display: false,
    },
    {
      title: "水設施課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/009.jpg",
      desc: ["8小時", "學習水設施原理與種類", "實際維修各種水設施"],
      display: false,
    },
    {
      title: "接案報價課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/010.jpg",
      desc: ["4小時", "學習操作師虎來了APP", "用報價系統與合理價格"],
      display: false,
    },
    {
      title: "工具使用課程",
      img: "../fdtiger-offical-site-img/tigerschool/school/img/011.jpg",
      desc: ["4小時", "學習各種工具與使用", "實際維修各種水設施"],
      display: false,
    },
  ];
  //now = 0;
  prepage = 3;
  nowpage = 1;
  isActive: number | null = 1;
  //clickPage = false;
  teacherList = [
    {
      id: "1",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/005.png",
      title: "師虎來了 陳師傅",
      desc1: "裝潢修繕、木工",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "2",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/006.png",
      title: "凱撒衛浴 林師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "3",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/007.png",
      title: "和成衛浴 李師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "4",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/006.png",
      title: "凱撒衛浴 林師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "5",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/007.png",
      title: "和成衛浴 李師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "6",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/005.png",
      title: "師虎來了 陳師傅",
      desc1: "裝潢修繕、木工",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "7",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/007.png",
      title: "和成衛浴 李師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "8",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/005.png",
      title: "師虎來了 陳師傅",
      desc1: "裝潢修繕、木工",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
    {
      id: "9",
      img: "../fdtiger-offical-site-img/tigerschool/school/png/006.png",
      title: "凱撒衛浴 林師傅",
      desc1: "水電服務、裝潢修繕",
      desc2: "忠誠水電工程 師傅35年經驗",
    },
  ];
  swiperOption3 = {
    slidesPerView: "2",
    centeredSlides: false,
    loop: true,
    observer: true,
    observeParents: true,
    followFinger: false,
    pagination: {
      el: ".swiper-pagination.swp3",
      type: "bullets",
      clickable: true,
    },
  };
  get pageslist(): {
    id: string;
    img: string;
    title: string;
    desc1: string;
    desc2: string;
  }[] {
    let start = (this.nowpage - 1) * this.prepage;
    return this.teacherList.slice(start, start + this.prepage);
  }
  //總共頁數
  get totalpage(): number {
    return Math.ceil(this.teacherList.length) / this.prepage;
  }
  //methods
  //合作夥伴按鈕點點
  changePages(item: number): void {
    //this.clickPage = true;
    let page = item;
    if (page < 1) {
      this.nowpage = 1;
    } else if (page > this.totalpage) {
      this.nowpage = this.totalpage;
    } else {
      this.nowpage = page;
    }
    this.isActive = page;
  }
  moveOnePage(move: number): void {
    let page = this.nowpage + move;
    if (page < 1) {
      this.nowpage = this.totalpage;
      this.isActive = this.totalpage;
    } else if (page > this.totalpage) {
      this.nowpage = 1;
      this.isActive = 1;
    } else {
      this.nowpage = page;
      this.isActive = page;
    }
  }
}
